import 'tippy.js/animations/scale.css';

import tippy from 'tippy.js';

//InitTippy========================================================================================================================================================
!(function initRaportList() {
    const raportTippyList = document.querySelectorAll(
        '[data-raport-tippy-box]'
    );

    raportTippyList.length &&
        raportTippyList.forEach((item) => {
            tippy(item.querySelector('[data-raport-tippy-handler]'), {
                placement: window.innerWidth > 991 ? 'bottom-end' : 'auto',
                content: item.querySelector('[data-raport-tippy-body]')
                    .innerHTML,
                maxWidth: window.innerWidth > 991 ? 'auto' : '320px',
                allowHTML: true,
                animation: 'scale',
                trigger: 'click',
            });
        });
})();
