if (window.location.href.indexOf('password/success') > -1) {
    let timeCounter = document.getElementById('timeCountToRedirect');

    if (timeCounter) {
        let el = timeCounter,
            total = el.innerHTML,
            timeInterval = setInterval(() => {
                total = --total;
                el.textContent = total;
                if (total <= 0) {
                    clearInterval(timeInterval);
                    window.location = window.origin + '/login';
                }
            }, 1000);
    }
}
